
import { Options, Vue } from 'vue-class-component';

import PrivacyContent from '@/components/PrivacyContent.vue';

@Options({
  components: {
    PrivacyContent,
  },
})
export default class PrivacyPolicy extends Vue {}
